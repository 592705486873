<template>
  <el-dialog
    class='dorm-access-dialog'
    :title='`更新权限模板-${title}`'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='540px'
    :before-close='cancel'
  >
    <el-form
      ref='form'
      :model='formData'
      :rules='rules'
      label-width='200px'
    >
      <el-form-item label='宿舍楼栋列表' prop='dormitoryId'>
        <el-select v-model='formData.dormitoryId' filterable>
          <el-option
            v-for='{dormitoryName, id} in dormBuildList'
            :label='dormitoryName'
            :value='id'
            :key='id'
          />
        </el-select>
      </el-form-item>
      <el-form-item label='是否为学生宿舍楼栋权限模板 ' prop='stuDormitoryFlag'>
        <el-switch
          v-model='formData.stuDormitoryFlag'
          :active-value='1'
          active-text='是'
          :inactive-value='0'
          inactive-text='否'
          active-color='var(--color-success)' />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type='primary' @click="save('宿舍楼权限模板 ',saveUserPertApi)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import dialog from '@/vue/mixins/dialog'
import { getDormBuildQuery } from '@/api/dorm'
import { saveUserPertApi } from '@/api/dorm/dorm-accress-api'

export default {
  name: 'dormAccessDialog',
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        dormitoryId: '', // 宿舍id
        stuDormitoryFlag: ''
      },
      rules: {
        campusName: [{ required: true, message: '请填写校区', trigger: 'blur' }]
      },
      dormBuildList: [] // 宿舍楼栋 list
    }
  },
  mounted () {
    this.getDormBuildList()
    this.$nextTick(() => {
      if (this.id) {
        // this.getDataById(this.id, getCampusById)
      } else {
        this.setInitData()
      }
    })
  },
  methods: {
    saveUserPertApi, // 保存 api,
    // 获取 所有宿舍楼栋
    async getDormBuildList () {
      try {
        const res = await getDormBuildQuery({})
        this.dormBuildList = res.data
      } catch (e) {

      }
    }
  }
}
</script>
