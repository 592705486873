<template>
  <table-view
    class='dorm-access'
    :show-page='false'>
    <!-- body -->
    <el-table :data='tableData' style='width: 100%' border stripe>
      <el-table-column type='index' label='ID' width='50' />
      <el-table-column prop='pertName' label='权限模板名称' width='200' />
      <el-table-column prop='gid' label='gid' min-width='300' />
      <el-table-column prop='dormitoryName' label='宿舍楼' min-width='300' />
      <el-table-column label='是否为学生宿舍楼栋权限模板' width='220'>
        <template v-slot='{row}'>
          <el-switch
            v-if='row.dormitoryId'
            v-model='row.stuDormitoryFlag'
            :active-value='1'
            active-text='是'
            :inactive-value='0'
            inactive-text='否'
            active-color='var(--color-success)'
            @change='handleDormitoryAccess($event, row)' />
          <span v-else class='font-grey'>未指定宿舍楼</span>
        </template>
      </el-table-column>
      <el-table-column label='操作' width='100' align='center'>
        <template v-slot='{ row }'>
          <el-button
            type='primary'
            size='mini'
            @click='showDialog(row)'
          >编辑
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 添加/编辑弹窗 -->
    <dorm-access-dialog ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog' @on-close='renderTable' />
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import DormAccessDialog from '@/views/pages/dormManage/dorm-access/component/dormAccessDialog.vue'
import { listUserPertApi, saveUserPertApi } from '@/api/dorm/dorm-accress-api'

export default {
  name: 'dormAccess',
  components: {
    DormAccessDialog
  },
  mixins: [tableView],
  data () {
    return {}
  },
  mounted () {
    this.renderTable()
  },
  methods: {
    async renderTable () {
      try {
        const res = await listUserPertApi()
        this.tableData = res.data
      } catch (e) {
      }
    },
    showDialog (row) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.dialogRef.title = row.pertName
        this.$refs.dialogRef.formData.id = row.id
        this.$refs.dialogRef.id = row.id
        this.$refs.dialogRef.formData.stuDormitoryFlag = row.stuDormitoryFlag
        if (row.dormitoryId) {
          this.$refs.dialogRef.formData.dormitoryId = row.dormitoryId
        }
        this.$refs.dialogRef.setInitData()
      })
    },
    handleDormitoryAccess (val, row) {
      this.$confirm(`是否确认将${row.pertName}设为学生宿舍楼栋权限模板?`, '更新权限模板').then(async () => {
        const _data = {
          id: row.id,
          stuDormitoryFlag: val
        }
        try {
          await saveUserPertApi(_data)
          this.$message.success('学生宿舍楼栋权限模板更新成功！')
          await this.renderTable()
        } catch (e) {
          row.stuDormitoryFlag = val ? 0 : 1
        }
      }).catch(() => {
        row.stuDormitoryFlag = val ? 0 : 1
        this.$message.info('已取消操作。')
      })
    }
  }
}
</script>
<style lang='scss'>
.dorm-access{
  height: calc(100vh - var(--header-height));
  background: #fff;
  box-sizing: border-box;
}
</style>
